import React from 'react';
import { useSelector } from 'react-redux';

function GoogleView() {
  const locationID = useSelector((state) => state?.location?.value?.kundenid);
  const shopLocationMap = useSelector((state) => state?.ShoplocationSlice?.value);

  const location = Array.isArray(shopLocationMap) 
  ? shopLocationMap.find((item) => item?.locations_id === locationID) 
  : undefined;

  // const location = shopLocationMap?.find((item) => item.locations_id === locationID);


  return (
    <section className="categorySectionSlider sliderSecond">
    <div className="container">
    { location && <h2 className="text-light mb-5">FILIALE {location.ORT}</h2>}
        <div className="360View mt-5">
        { location ? (
            <iframe
        src={location.goolge360}
        title="360 View here"
        width="100%"
        height="700px"
        style={{ border: 'none' }}
        allowFullScreen
      />  ) : null
}
        </div>
    </div>.
    
</section>
  )
}

export default GoogleView