import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProductInfo from "./ProductInfo";
import apiConfig from "./../../apiConfig";
import { fetchArticle } from "../../features/ArticleSlice";

const ProductDetailPage = () => {
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const [ArticleLocation, serArticleLocation] = useState(null);
  let { id } = useParams();
  const locationID = useSelector((state) => state?.location?.value?.kundenid);

  const ArticlesList = useSelector((state) => state.ArticleSlice.value);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiConfig.apiBasePath}getArticleLocations`, {
          method: 'POST',
          body: JSON.stringify({
            articleID:id
          })
      });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        serArticleLocation(result);
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };

    // dispatch(fetchArticle(8));

    if(!Array.isArray(ArticlesList) ){
      if (locationID) {
        // Make sure locationID is not undefined before dispatching
        dispatch(fetchArticle(locationID));
        // dispatch(fetchArticleCategory());
      }
    }
    
    fetchData();
    const currentArticle = Array.isArray(ArticlesList) ? ArticlesList.filter((item) => item.ARTIKELNR === id):[];
    setProduct(currentArticle);
  }, [ArticlesList,id,locationID]);



  return (
    <div>
      {product ? (
        product.map((items, index) => (
           <ProductInfo key={index} items={items} ArticleLocation={ArticleLocation}/>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ProductDetailPage;
