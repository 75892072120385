import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CollageImages from "./CollageImages";
import { useSelector, useDispatch } from "react-redux";

function CategoriesDetail({ item }) {
  const [count, setCount] = useState(null);

  const ArticlesList = useSelector((state) => state.ArticleSlice.value);
  useEffect(() => {
    if(Array.isArray(ArticlesList)){
    let counts = ArticlesList.filter((itemA) =>
      itemA?.WARENGRUPPEN_ID.toLowerCase().includes(item.WARENGRUPPEN_ID)
    ).slice(0, 4).length;
    if (counts === 3) {
      setCount(2);
    } else {
      setCount(counts);
    }
}
  }, [ArticlesList, setCount, item.WARENGRUPPEN_ID]);

  return (
    <>
      {count > 0 && (
        <li className="text-center">
          <NavLink to={`/artikel/${item.WARENGRUPPEN_ID}`}>
            <div className={`collage ${count}`}>
              {Array.isArray(ArticlesList) &&
                ArticlesList.filter((itemA) =>
                  itemA?.WARENGRUPPEN_ID.toLowerCase().includes(
                    item.WARENGRUPPEN_ID
                  )
                )
                  .slice(0, count)
                  .map((Article, index) => (
                    <CollageImages
                      key={index}
                      Article={Article}
                      count={count}
                    />
                  ))}
            </div>
            <span>{item.BEZEICHNUNG}</span>
          </NavLink>
        </li>
      )}
    </>
  );
}

export default CategoriesDetail;
