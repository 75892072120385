import React, { useEffect, useRef, useState } from 'react'
import CountdownTimer from './CountDownTimer'
import apiConfig from '../../apiConfig';
import { NavLink } from 'react-router-dom';
import SingleSlider from './SingleSlider';
import { useSelector } from 'react-redux';
function WeeklyHighlight() {

  const [itemsList,setItemsList] = useState([]);
  const [itemsId,setItemsId] = useState();
  const locationID = useSelector((state) => state?.location?.value?.kundenid);
  const [itemsClass,setItemsClass] = useState('col-md-3');
  const [imgClass,setImgClass] = useState('65%');
  const { protocol, hostname, port } = window.location;

  const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;

  const settings = {
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  let shreURl =   `${baseUrl}${process.env.PUBLIC_URL}/aufnachwolfen`
  let shareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://technikkenner.de/technikkenner_api/meta/aufnachwolfen`;

  const body = `Moin Moin\n\n  anbei ein Angebot von  M.S. Media Electronic Duderstadt GmbH:\n\n${shreURl} \n\n Klingt interessant und musste an dich denken. \n\n Lieben Gruß \n \n Dies ist ein kostenloser Service von WOCHENHIGHLIGHT.DE – Alle Prospekte kostenfrei online. Sent from Mail for Windows`;

  const mailtoLink = `mailto:?subject=${encodeURIComponent('Angebote KW26')}&body=${encodeURIComponent(body)}`;
  const imageList = '';
  useEffect(() => {  
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiConfig.onePassBasePath}/getwochenhighlight/39/70`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        // console.log(result,"locationaa")
        setItemsList([result.data[result.data.length-1]]);
        setItemsId(result.data[result.data.length-1].id);
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();

  }, []);
  return (
   <>
     <section className="innerPageBanner">
        <img
          className="d-md-block"
          src="assets/images/banner_aufnachwolfen.png"
          alt=""
        />
      </section>


      <CountdownTimer targetDate={"2024-09-30T09:00:00"}/>


      <section className='aktionenSec pt-5'>
        <div className="container">
        <div className="aktionenSecHead">
            <h2>AKTIONEN</h2>
        </div>
        <div className='offerMain pb-5'>
           <img src="assets/images/aktinoen-banner.png" ></img>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <img src="assets/images/aktionen_1.png" />
          </div>
          <div className="col-12 col-md-4">
            <img src="assets/images/aktionen_2.png" />
            </div>
          <div className="col-12 col-md-4">
            <img src="assets/images/aktionen_3.png" />
            </div>
        </div>
        <div className='offerMain videoOffer pb-5'>
        
           <video src="./assets/videos/aktionen_video.mp4" controls poster="assets/images/aktionen_video_poster.png" ></video>
        </div>
        </div>
      </section>
      <div className="innepageBody pb-5">
        <div className="catelogSection">
          <div className="container">
            <div className="card catelogCard">
              <div className="card-body">
                <h2 className="card-title text-light m-0">{itemsList[0]?.name}</h2>
                <div className="cardDetails">
                  {itemsList && (
                    <div className='row'>
                      {
                        itemsList?.map((items)=>(
                          <>
                            <SingleSlider imageList={items.medien} settings={settings} key={'ch'} />
                          </>
                        ))
                      }
                      
                      
                     <div className="nav-slider">                  
                      <span className="fbshare_icon_container_wh">
                        <a href={shareUrl} className="a_wh" title="Auf Facebook teilen" alt="Auf Facebook teilen" target='_blank' >
                          <span className="fbshare_icon_wh"></span>
                        </a>
                        
                      </span>
                      <span className="whashare_icon_container_overview_wh">
                        <a href={"https://api.whatsapp.com/send?text=Mega%20Angebot.%20Vielleicht%20was%20für%20dich:%20"+shreURl+""} className="a_wh" title="Auf WhatsApp teilen" alt="Auf WhatsApp teilen" target="_blank">
                          <span className="whashare_icon_wh"></span>
                        </a>
                      </span>
                      <span className="mailshare_icon_container_wh">
                        <a href={mailtoLink} className="a_wh" title="Als E-Mail teilen" alt="Als E-Mail teilen">
                          <span className="mailshare_icon_wh"></span>
                        </a>
                      </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ProductSlider /> */}
      </div>

      
   </>
  )
}

export default WeeklyHighlight