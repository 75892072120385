import React, { useEffect,useState } from 'react'
import { useSelector } from "react-redux"
import apiConfig from '../../apiConfig';
import LocationMap from './LocationMap';

function LocationAddress() {
    
  const [location, setLocation] = useState(null);
    const locationID = useSelector((state) => state?.location?.value?.kundenid);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${apiConfig.apiBasePath}locationList`);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const result = await response.json();
            setLocation(result);
          } catch (error) {
            // setError(error.message);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchData();
      }, []);


  return (
    <section className="contactArea">
        <div className="container">
          <div className="row">
            <div className="col-lg- 6 col-md-7 pe-md-0">
              <div className="contactData">
                <div className="row">
                  <div className=" col-lg-7 col-md-12">
                    <div className="contactInfo">
                      {Array.isArray(location) &&
                        location
                          .filter((item) => item.locations_id === locationID)
                          .map((loc, i) => (
                            <>
                            <div key={i}>
                              <h6>MEDIMAX - {loc.ORT}</h6>
                              <p>{loc.BEZEICHNUNG}</p>
                              <p>
                                {loc.STRASSE} <br/> {loc.PLZ} {loc.ORT}
                              </p>
                              <h6>TELEFON:</h6>
                              <p>
                                <a href={`tel:${loc.TEL}`}>{loc.TEL}</a>
                              </p>
                              <h6>E-MAIL:</h6>
                              <p>
                                <a href={`mailto:${loc.EMAIL}`}>{loc.EMAIL}</a>
                              </p>
                              </div>
                            </>
                          ))}
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12">
                    <div className="contactInfo rightData">
                      <h6>ÖFFNUNGSZEITEN</h6>
                      <p>
                        MO-FR: 10:00 - 19:00
                        <span className="d-block">SA: 10:00 - 18:00</span>
                      </p>
                      <img
                        src="assets/images/Icon_Kontakt_Speichern-1.png"
                        alt=""
                      />
                      <img src="assets/images/Icon_Routenplaner.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-5 ps-md-0"
              
            >
               
             <LocationMap/>
            
            </div>
          </div>
        </div>
      </section>
  )
}

export default LocationAddress