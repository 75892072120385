import { React, useEffect, useState } from 'react'
import VideoPagination from './VideoPagination'
import VideoSearch from './VideoSearch'
import VideoSlider from './VideoSlider'
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from "react-cookie"

const VideoList = ({ videoListing }) => {
    const [cookies, setCookie] = useCookies(["cookiesAccepted"]);  
  const [isVisible, setIsVisible] = useState(!cookies.cookiesAccepted)

    // const videoListing = videoListing;
    const [originalData] = useState(videoListing);
    const [filteredData, setFilteredData] = useState(videoListing);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const [modelVideo, setModelVideo] = useState();

    const handleSearch = (query) => {
        const filtered = originalData.filter(item =>
            item.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredData(filtered);
        setCurrentPage(1); // Reset to first page after filtering
    };

    const paginate = (array, page_number, page_size) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleVdieoModel = (videoId) => {
        setModelVideo(videoId);
    }
    const onClose = () => {
        setModelVideo();
    }


    return (
        <>
            {videoListing.length > 0 && !isVisible && (
                <VideoSlider 
                  videoListing={videoListing} 
                  handleVdieoModel={handleVdieoModel} 
                />
            )}

            <section className="bg-dark-new py-5">
                <div className="container">
                    <div className="row">
                    <VideoSearch handleSearch={handleSearch} />
                        { !isVisible &&
                        
                        filteredData?.length > 0 ? (paginate(filteredData, currentPage, itemsPerPage).map((video, i) => (
                            <div key={i} className="col-lg-4 col-md-6 mb-4">

                                <div className=" imageWrap videoWrap">
                                    <img src={video?.image} style={{ width: "100%" }} onClick={() => handleVdieoModel(video?.videoId)}/>
                                    <h4 style={{ color: "white" }} >{video?.title}</h4>
                                    {/* <iframe
                                        src={`https://www.youtube.com/embed/` + video?.videoId}
                                        frameBorder='0'
                                        allow='autoplay; encrypted-media'
                                        allowFullScreen
                                        title='video'
                                        width={'90%'}
                                        height={'335px'}
                                    /> */}
                                    {/* <img src={video?.image} onClick={() => handleVdieoModel(video?.videoId)} /> */}
                                </div>
                            </div>
                        ))) : <div className="col-lg-4 col-md-6 mb-4">
                            <div className=" imageWrap videoWrap">
                                <h3 className="text-white">Kein Video gefunden</h3>
                            </div>
                        </div>

                    }

                    </div>
                </div>
            </section >
            <VideoPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={filteredData.length}
                onPageChange={handlePageChange}
            />
            {(modelVideo && (
                <div className="modal-overlay">
                    <div className="modal" style={{ display: "block", marginTop: "200px" }}>
                        <button className="close-button" onClick={onClose} style={{ position: "absolute", zIndex: "999", width: "50px" }}>
                            X
                        </button>
                        <iframe
                            src={`https://www.youtube.com/embed/` + modelVideo}
                            frameBorder='0'
                            allowFullScreen
                            title='video'
                            width={'50%'}
                            height={'600px'}
                        />
                    </div>
                </div>))}
        </>
    )
}

export default VideoList