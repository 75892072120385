import React from 'react'
import Slider from "react-slick";
import SliderImage from './SliderImage';
const SingleSlider = (props) => {
    const settings = props.settings;
    const imageList = props.imageList;
    const key = props.key;

    const settingsNew = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
    };
    console.log('imageList',imageList)
    return (
        <div key={key} className="col-md-8 mx-auto bookSlider">
            <Slider {...settings}>
                {imageList?.map((medien, i) => (
                    <div className="imageWrap"  >
                        <SliderImage medien={medien} key={i}/>
                    </div>
                ))}
            </Slider>
            
        </div>
    )
}

export default SingleSlider