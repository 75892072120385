import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../../apiConfig';



// Fetch location info
export const fetchLocationInfo = createAsyncThunk(
  'fetchLocationInfo',
  async (locations) => {
    const res = await fetch(`${apiConfig.onePassBasePath}/getwochenhighlight/39/${locations.wochenhighlight_id}`);
    const data = await res.json();
    data.wochenhighlight_id = data.kundenid;
    data.kundenid = locations.locations_id;
    data.locations_id = locations.locations_id;
    return data;
  }
);



 const initialState = {
    value: fetchLocationInfo(3)
}


 const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchLocationInfo.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchLocationInfo.fulfilled, (state, action) => {
          state.status = 'idle';
          state.value = action.payload;
        })
        .addCase(fetchLocationInfo.rejected, (state) => {
          state.status = 'failed';
        });
    },
  });
  
  export const {} = locationSlice.actions
  
  export default locationSlice.reducer
