import React, { useState, useEffect } from 'react'

function ArticleAvailaleLocations({shop ,ArticleLocation }) {
  
    const [chk, setChk] = useState(false);

    useEffect(() => {
        const idExists = ArticleLocation.some(item => item.id === shop.id);
        setChk(idExists);
    }, [ArticleLocation, shop.id]);

  return (
    <li className="text-start">
        
        {chk ? (
         <i className="fas  fa-circle" style={{ color: 'green' }}></i>
        ) : (
         <i className="fas fa-circle" style={{ color: 'red' }}></i>
        )}
        {shop.ORT}
    </li>
  )
}

export default ArticleAvailaleLocations