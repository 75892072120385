import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../../apiConfig';

const initialState = {
    value: {}
}

export const fetchPartner = createAsyncThunk("fetchPartner", async () => {
    const res = await fetch(`${ apiConfig.apiBasePath }getPatnerList`);
    return res?.json();
 });

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPartner.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPartner.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {} = partnerSlice.actions

export default partnerSlice.reducer