import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../../apiConfig';



export const fetchTopSliderInfo = createAsyncThunk("fetchTopSliderInfo", async () => {
    const res = await fetch(`${apiConfig.apiBasePath}getTopSlider`);
    return res?.json();
 });

 const initialState = {
    value: fetchTopSliderInfo()
}


 const TopSliderSlice = createSlice({
    name: 'topSliderSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTopSliderInfo.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchTopSliderInfo.fulfilled, (state, action) => {
          state.status = 'idle';
          state.value = action.payload;
        })
        .addCase(fetchTopSliderInfo.rejected, (state) => {
          state.status = 'failed';
        });
    },
  });
  
  export const {} = TopSliderSlice.actions
  
  export default TopSliderSlice.reducer
