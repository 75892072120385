import React,{useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

function TopSlider() {
  const [sliderList, setSliderList] = useState(null);
  const topSliderSlice = useSelector((state) => state?.topSliderSlice?.value);
  let CurrentShop = useSelector((state) => state?.location?.value?.kundenid);
  useEffect(() => {
    Array.isArray(topSliderSlice) && setSliderList(topSliderSlice);
    let selectLocation = localStorage.getItem("selectLocation");
    if(selectLocation)
      CurrentShop = JSON.parse(selectLocation).locations_id;
    const filterImage = topSliderSlice?.filter((img)=>{
        if(img.image_name === 'Aufnachwolfen'){
          if(CurrentShop*1 == 6){
             return img
            }
      }else{
        return img
      }
    });
    setSliderList(filterImage)
  }, [topSliderSlice,CurrentShop]);
  
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  return (
    <Slider {...settings} className="bannerMain">
      {sliderList && sliderList.map((slider) => (
        <div className="slide" key={slider.id}> {/* Add a unique key for each slide */}
          <a href={slider.link} target="_blank" rel="noopener noreferrer"> {/* Fixed target attribute and added rel for security */}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${slider.large_image}`}
              alt={slider.image_name || 'Slide image'} 
              style={{ maxHeight: "578px" }}
            />
          </a>
        </div>
      ))}
      </Slider>
  );
}

export default TopSlider;
