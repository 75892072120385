import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

const MainSlider = ({ images, navSliderRef, mainSliderRef }) => {
  const [filteredImages, setFilteredImages] = useState([]);

  const settings = {
    asNavFor: navSliderRef.current,
    dots: true,
    infinite:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    const jpgImages = images.filter((image) => image.toLowerCase().endsWith('.jpg'));
    if (jpgImages.length > 0) {
      setFilteredImages(jpgImages);
    } else {
      setFilteredImages(images.filter((image) => image.toLowerCase().endsWith('.png')));
    }
  }, [images]);

  return (
    <Slider {...settings} ref={mainSliderRef}>
      {filteredImages.map((image, index) => (
        <div className="images p-3" key={index}>
          <img
            alt={`Slide ${index}`}
            src={image.includes('https') ? image : `https://onepas.de/v3/msmedia/${image}`}
            className="large-image"
          />
        </div>
      ))}
    </Slider>
  );
};

MainSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  navSliderRef: PropTypes.object.isRequired,
  mainSliderRef: PropTypes.object.isRequired,
};

MainSlider.defaultProps = {
  images: [],
};

export default MainSlider;