import React from "react";
import Slider from "react-slick";

function IdeaSlider({ partnerDetail, settings, keyId }) {
  console.log(partnerDetail,'partnerDetail')
 
  return (
    <>
      {partnerDetail?.idea_slider[keyId] &&
        partnerDetail?.idea_slider[keyId].filter(
          (item) => item.idea_type === "slider"
        ) && (
          <section className={`ideasSlider py-4 mb-5 pb-5 ${keyId}`}>
            <div className="container">
              
              {partnerDetail?.idea_slider[keyId] &&
                partnerDetail?.idea_slider[keyId]
                  .filter((item) => item.idea_type === "heading")
                  .map((item, index) => (
                    
                    <div className={`heading ${index}`}>
                     {item.title && <h2
                        className="partern_head ideas_head"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                        key={index}
                      ></h2>}
                      {item.description && <h4
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></h4>}
                      {item.image.endsWith(".mp4") ? ( // Check if image is a video
                      <span>
                                <video controls>
                                  <source
                                    src={`../assets/${item.image}`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                </span>
                              ) : (
                               (item.image.endsWith(".png") || item.image.endsWith(".jpg")) && (<span>
                                <img
                                  src={`../assets/${item.image}`}
                                  alt="card"
                                />
                                </span>)
                              
                              )}

                      {/* <span>
                        <img src="../assets/images/leaft-head.png" alt="" />
                      </span>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></p> */}
                    </div>
                  ))}
              <div className="product_slider commonSlick" id="ideasSlider">
                <Slider {...settings} className="videoOne commonSlick">
                  {partnerDetail?.idea_slider &&
                    partnerDetail?.idea_slider[keyId]
                      .filter((item) => item.idea_type === "slider")
                      .map((item, index) => (
                        <div className="sliderInnerWrapper" key={index}>
                          <div className="blog-card">
                            <div className="IdeasCardInner">
                              {item.image.endsWith(".mp4") ? ( // Check if image is a video
                                <video controls>
                                  <source
                                    src={`../assets/${item.image}`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={`../assets/${item.image}`}
                                  alt="card"
                                />
                              )}
                              <h3
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              ></h3>
                              {item.description && <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></p>}
                            </div>
                          </div>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
          </section>
        )}
    </>
  );
}

export default IdeaSlider;
