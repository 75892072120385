import React, { useEffect } from 'react'
import VideoList from './VideoList';
import { fetchVideo } from '../../features/videoYoutube/videoSlice';
import { useSelector, useDispatch } from 'react-redux'

const VideoLibrary = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchdata = async () => {
      dispatch(fetchVideo());
    }
    fetchdata();
  }, []);

  const videoListing = useSelector((state) => state.video.value);




  return (
    <>
      <section className="innerPageBanner">
        <img className="d-none d-md-block" src="assets/images/Headerbanner-Videos.jpg" alt="" />
        <img className="d-block d-md-none" src="assets/images/Kategorie-Technikkenner.jpg" alt="" />
      </section>
      <div className="innepageBody">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center text-light">
              <h2>VIDEOTHEK</h2>
              <h5 className="mb-5">Ganz nach dem Motto: "Mehr Mensch - Mehr Technik" haben wir uns vorgenommen,
                Technologien zu
                erklären, bei denen sich bestimmt jeder schonmal gefragt hat: "Was ist das eigentlich".</h5>
            </div>
          </div>
        </div>
      </div>
      {videoListing.length > 0 && (<VideoList videoListing={videoListing} />)}
     

    </>
  );
}

export default VideoLibrary