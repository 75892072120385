import React, { useEffect } from "react";
import { useState } from "react";
import apiConfig from "../../apiConfig";
import LocationAddress from "../Home/LocationAddress";
import Modal from "../Modal";
import qs from "qs";
import { useCookies } from "react-cookie"
import LocationMap from "../Home/LocationMap";
import { useSelector, useDispatch } from "react-redux";
import { fetchArticle } from "../../features/ArticleSlice";
import { useSearchParams } from "react-router-dom";



const ContactUs = () => {
  const [cookies, setCookie] = useCookies(["cookiesAccepted"]);  
  const [isVisible, setIsVisible] = useState(!cookies.cookiesAccepted)
  const locationID = useSelector((state) => state?.location?.value?.kundenid);
  const ArticlesList = useSelector((state) => state.ArticleSlice.value);
  const [Loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [mailSent, setMailSent] = useState([]);
  const [ArticleLocation, serArticleLocation] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  let currentArticle = [];
  let artikelId = null;

  const [formData, setFormData] = useState({
    vorname: "",
    Nachname: "",
    email: "",
    Telefon: "",
    nachricht: "",
    Articlenumber: "",
    Articlename: "",
    Articleprice: "",
    Articlequantity: "",
    Articlestore: ""
  });

  if(searchParams.get('artikel')){
    artikelId = searchParams.get('artikel');
    currentArticle = Array.isArray(ArticlesList) ? ArticlesList.filter((item) => item.ARTIKELNR === artikelId):[];
    

  }

  useEffect(()=>{
    console.log('useEffect call');
    if(searchParams.get('artikel'))
      fetchData();

    // setArticlesList(ArticlesList);
    if(!Array.isArray(ArticlesList) ){
      if (locationID) {
        // Make sure locationID is not undefined before dispatching
        dispatch(fetchArticle(locationID));
        // dispatch(fetchArticleCategory());
      }
    }
  },[]);

  const fetchData = async () => {
    try {
      setFormData({
        ...formData,
        Articlenumber: currentArticle[0]?.ARTIKELNR,
        Articlename: currentArticle[0]?.BEZEICHNUNG,
        Articleprice: currentArticle[0]?.VK?currentArticle[0].VK:'N/A',
      }); 
      const response = await fetch(`${apiConfig.apiBasePath}getArticleLocations`, {
                            method: 'POST',
                            body: JSON.stringify({
                              articleID:artikelId
                            })
                        });
      console.log("getArticleLocations response",response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      const result = await response.json();
      console.log("getArticleLocations",result);
      serArticleLocation(result);
    } catch (error) {
      // setError(error.message);
      console.error(error)
    } finally {
      // setLoading(false);
    }
  };
  
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };



  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await fetch(`${apiConfig.apiBasePath}contactus`, {
        method: "POST",
        body: qs.stringify(formData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const data = await res.json(); // Parse the JSON response
      setMailSent(data);
    } catch (error) {
      console.error("Error:", error);
      setMailSent("There was an error sending the email.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <section className="formCstm py-5">
        <div className="container">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <h2>Kontakt</h2>
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Vorname:*"
                  className="form-control"
                  id="k_vorname"
                  name="vorname"
                  value={formData.vorname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Nachname:*"
                  className="form-control"
                  id="Nachname"
                  name="Nachname"
                  value={formData.Nachname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  placeholder="E-Mail:*"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Telefon:"
                  className="form-control"
                  id="Telefon"
                  name="Telefon"
                  value={formData.Telefon}
                  onChange={handleChange}
                />
              </div>
              {currentArticle.length >0 && 
              (<>
              
                <div className="mb-3">
                  <input readOnly
                  type="text"
                  placeholder="Articlenumber"
                  className="form-control"
                  id="Articlenumber"
                  name="Articlenumber"
                  required
                  value={currentArticle[0].ARTIKELNR}
                  onChange={handleChange}/>
                </div>
               <div className="mb-3">
                <input readOnly
                type="text"
                placeholder="Articlename"
                className="form-control"
                id="Articlename"
                name="Articlename"
                required
                value={currentArticle[0].BEZEICHNUNG}
                onChange={handleChange}/>
              </div>
              <div className="mb-3">
              <input readOnly
              type="text"
              placeholder="Articleprice"
              className="form-control"
              id="Articleprice"
              name="Articleprice"
              required
              value={currentArticle[0].VK?currentArticle[0].VK:'N/A'}
              onChange={handleChange}/>
            </div>
            <div className="mb-3">
            <select required className="Articlequantity form-control" name="Articlequantity" id="Articlequantity" onChange={handleChange}>
            <option value="">Menge:</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div className="mb-3">
            {ArticleLocation.length ?
              (
                <>
                <select key={123} required className="Articlestore form-control" name="Articlestore" id="Articlestore" onChange={handleChange}>
                <option value="">Auswahl Filiale:</option>        
                  {ArticleLocation.map((singleLocation, i)=>(
                    <>
                      <option key={i} value={singleLocation.ORT}>{singleLocation.ORT}</option>        
                    </>
                  ))}
                </select>
                </>
              )
              :
              (
                <select className="Articlestore form-control" name="Articlestore" id="Articlestore">
                  
                    <>
                      <option key={1} value="Anfrage stellen, kein Lagerartikel">Anfrage stellen, kein Lagerartikel</option>        
                    </>

                </select>
              )
            }
        </div>
        </>
              )
              
              }
              <div className="mb-3">
                <textarea
                  className="w-100 form-control placeholder="
                  placeholder="Nachricht*"
                  font_rw_std=""
                  h6=""
                  c_col2=""
                  border=""
                  border-1=""
                  c_brdcol1=""
                  rounded=""
                  rows="5"
                  id="nachricht"
                  name="nachricht"
                  value={formData.nachricht}
                  onChange={handleChange}
                  maxLength="250"
                  required
                ></textarea>
              </div>
              <div className="mb-3 check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="checkbox_kontakt_datenschutz"
                  name="checkbox_datenschutz"
                  required
                />
                <label
                  className="form-check-label c_col1"
                  for="checkbox_kontakt_datenschutz"
                >
                  Ich erkläre mich hiermit einverstanden, dass gemäß{" "}
                  <a className="c_textlink c_col5" onClick={handleOpenModal}>
                    DSGVO
                  </a>{" "}
                  meine übermittelten Daten zur Verarbeitung gespeichert werden.
                  Es findet keine Weitergabe an Dritte statt.
                </label>
              </div>
              <div className="mb-3">
                <label className="w-100 font_RW_STD h6 c_col1 text-start">
                  * = Pflichtfelder
                </label>
              </div>
              <div className="form_section">
                <input type="submit" value="Submit" />
                {Loader && <span className="loder-spinner"></span>}
              </div>
              {mailSent?.status === "success" && (
                <div className="bg-success p-3 rounded w-50 m-auto">
                  <p className="from-section-content text-white m-0">
                    {mailSent?.message}
                  </p>
                </div>
              )}
              {mailSent?.status === "error" && (
                <div className=" bg-danger p-3 rounded w-50 m-auto">
                  <p className="from-section-content text-white m-0">
                    {mailSent?.message}
                  </p>
                </div>
              )}
            </form>
            <div className="location">
            { !isVisible && 
             <LocationMap/>
            }
            </div>
          </div>
        </div>
      </section>
      <LocationAddress />
      <section className="contactArea">
        <div className="container">
        
          <div className="row">
            <Modal show={showModal} onClose={handleCloseModal}>
              <h2>Modal Title</h2>
              <p>This is the modal content.</p>
              <button onClick={handleCloseModal}>Close</button>
            </Modal>
          </div>
        
        </div>
      </section>
    </>
  );
};

/* HTML: <div class="loader"></div> */

export default ContactUs;
