
import { React, useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActionSlider from "./ActionSlider";
import PopularCategores from "./PopularCategores";
import ProductSlider from "./ProductSlider";
import TopSlider from "./TopSlider";
import Partnerslider from "./Partnerslider";
import ShopAddress from "./ShopAddress";
import GoogleView from "./GoogleView";


import { fetchLocationInfo } from '../../features/shopLocation/locationSlice';
import { fetchArticle } from '../../features/ArticleSlice'
import { fetchPartner } from '../../features/PartnerList/partnerSlice';
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from "react-cookie"
import LocationAddress from './LocationAddress';
import apiConfig from "../../apiConfig";

const Home = (props) => {
  const [cookies, setCookie] = useCookies(['cookiesAccepted']);
  const [isVisible, setIsVisible] = useState(!cookies.cookiesAccepted);
  const dispatch = useDispatch();
  const locationID = useSelector((state) => state?.location?.value?.kundenid);
  const chkLocation = useSelector((state) => state.location);

  useEffect(() => {
    const fetchData = async (shopLocation) => {
      const NearlocationResult = await fetch(`${apiConfig.apiBasePath}getNearShopLocation`);
      if (!NearlocationResult.ok) {
        throw new Error('Network response was not ok');
      }
      const NearlocationRes = await NearlocationResult.json();
      console.log('result',NearlocationRes)

      const response = await fetch(`${apiConfig.apiBasePath}locationList`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        let listlocaiton = result.filter((loc) => loc.locations_id == NearlocationRes)[0];
      dispatch(fetchLocationInfo(listlocaiton));
      dispatch(fetchPartner());
    };

    if (chkLocation?.status) {
      // If location status exists, fetch partner info
      dispatch(fetchPartner());
    } else {
      // If location status does not exist, fetch location info
      fetchData(3); // Ensure that `2` is the correct value or adjust accordingly
    }

    // Update visibility based on cookies
    setIsVisible(!cookies.cookiesAccepted);
  }, [chkLocation, cookies.cookiesAccepted, dispatch]);



  console.log('isVisible', isVisible);

  useEffect(() => {
    const fetchData = async () => {
      if (locationID) { // Make sure locationID is not undefined before dispatching
        dispatch(fetchArticle(locationID));
      }
    };
    fetchData();
  }, [locationID]);

  const shopLocation = useSelector((state) => state.location.value);

  return (
    <>
      <section className="bannerMain">
        <TopSlider />
      </section>
      <section className="categorySection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-light text-center mb-5">BELIEBTE KATEGORIEN</h2>
              <PopularCategores />
            </div>
          </div>
        </div>
      </section>
      <section className="categorySectionSlider">
        <div className="container">
          <div className="row">
            <ProductSlider Article={shopLocation} />
          </div>
        </div>
      </section>
      <section className="categorySectionSlider sliderSecond">
        <div className="container">
          <h2 className="text-light mb-3 mb-md-5">AKTIONEN</h2>
          <div className="row">
            <ActionSlider  />
          </div>
        </div>
      </section>
      <section className="categorySectionSlider sliderSecond">
        <div className="container">
          <h2 className="text-light mb-3 mb-md-5">PARTNER</h2>
          <div className="row">
            <Partnerslider />
          </div>
        </div>
      </section>
     { !isVisible && (
      <>
      <GoogleView />
      <LocationAddress />
      </>

     )

    
      
}

    </>
  );
}
export default Home;