import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <>
      {timeLeft.days !== undefined ? (
        <>
          <section className="countdown_Sec py-5" style={{
                    backgroundImage: `url(./assets/images/countdown_Bg.png)`,
                  }}>
            <div className="container">
              <div>
                <div
                  className="count-down-outer"
                >
                  <div className="">
                    <h2>COUNTDOWN BIS ZUR ERÖFFNUNG</h2>
                  </div>
                  <div className="countdown">
                    {(timeLeft.days != 0)&& 
                        <p><strong>{timeLeft.days}</strong> <span>Tage</span></p>
                    }
                    { 
                    (timeLeft.days != 0)
                    && (timeLeft.hours !=0) 
                    && <p>
                        <strong>{timeLeft.hours}</strong> 
                        <span>Stunden</span>
                        </p>
                    }
                    { 
                    (timeLeft.days != 0)
                    && (timeLeft.hours !=0) 
                    &&  (timeLeft.minutes !=0) 
                    && <p>
                        <strong>
                            {timeLeft.minutes}
                        </strong> 
                        <span>Minuten</span>
                        </p>
                        }
                    <p><strong>{timeLeft.seconds}</strong> <span>Sekunden</span></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CountdownTimer;
