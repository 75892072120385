import React,{ useRef,useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Slider from "react-slick";
import MainSlider from './MainSlider';
import NavSlider from './NavSlider';
import apiConfig from "../../apiConfig";
import ArticleAvailaleLocations from "./ArticleAvailaleLocations";

function ProductInfo({ items, ArticleLocation }) {
    const [images, setImages] = useState(null);
    const [category, setCategory] = useState(null);
    const [locations, setLocations] = useState(null);
      

   const catinfo = useSelector((state) => state?.ArticleCategorySlice?.value);

   useEffect(() => {
    setCategory(catinfo);
  }, [catinfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiConfig.apiBasePath}locationList`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setLocations(result);
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [ArticleLocation]);



  useEffect(() => {
    if ( items?.images != null) {
      setImages(items?.images.split(','));
    }
  },[items]);

  const mainSliderRef = useRef(null);
  const navSliderRef = useRef(null);


  return (
    <>
      <div className="container mt-5 mb-5 article">
      <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li>
      <NavLink to="/">
      Hauptseite
      </NavLink> </li> <li> 
        {/* <NavLink to={`/artikel/${items?.WARENGRUPPEN_ID ? items?.WARENGRUPPEN_ID : 0}/1`}> */}
        <NavLink to={`/artikel/0/1`}>
        Liste der Artikel
      {/* {category && category.filter(data => data.WARENGRUPPEN_ID == items?.WARENGRUPPEN_ID ).map(info =>(
        info.BEZEICHNUNG
      )) } */}
      </NavLink> </li>
      </ol></nav>
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="row">
                <div className="col-md-6">
                {images &&(
                 <MainSlider images={images} navSliderRef={navSliderRef} mainSliderRef={mainSliderRef} />)}
               {images &&( <NavSlider images={images} navSliderRef={navSliderRef} mainSliderRef={mainSliderRef} />)}
                  

                  {/* <div className="text-center p-4"> <img id="main-image" src="https://i.imgur.com/Dhebu4F.jpg" width="250" /> </div>
                            <div className="thumbnail text-center">   </div> */}
                </div>
                <div className="col-md-6">
                <img id="main-image" src={items?.brandImg} />
                    {/* {items?.brandName} */}
                  <div className="product p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {" "}
                        <i className="fa fa-long-arrow-left"></i>{" "}
                      </div>{" "}
                      <Link data-price={items.VK} to={"/filialen/?artikel=" + items?.ARTIKELNR} ><i className="fa fa-shopping-cart text-muted"></i></Link>
                    </div>
                    <div className="mt-4 mb-3">
                    
                    
                    
                      <span className="text-uppercase text-muted brand">
                        {items?.cat}
                      </span>
                      <h5 className="text-uppercase">{items?.BEZEICHNUNG}</h5>
                      <div className="price d-flex flex-row align-items-center">
                        {" "}
                        <span className="act-price">{items?.VK} €</span>
                        {/* <div className="ml-2"> <small className="dis-price">{items?.VK} €</small> <span>40% OFF</span> </div> */}
                      </div>
                    </div>
                    <p className="about">{items?.BESCHREIBUNG_KURZ_ORIGINAL}</p>
                    <h6 className="text-start" >Verfügbarkeit</h6>
                    <ul className="text-start">
                    {locations && locations.map((shop, Index) =>(
                      <ArticleAvailaleLocations shop={shop} ArticleLocation={ArticleLocation} />
                    ))}
                    </ul>


                    {/* <div className="cart mt-4 align-items-center"> <button className="btn btn-danger text-uppercase mr-2 px-4">Add to cart</button> <i className="fa fa-heart text-muted"></i> <i className="fa fa-share-alt text-muted"></i> </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductInfo;
