import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const SliderImage = (props) => {
    const medien = props.medien;
    const key = props.key;
    return (
        <>
            {medien.map((image) => (
                 <Zoom 
                 zoomMargin={40}
            // zoomImg={{ src: image, alt: image, style: { width: 'auto', height: 'auto', maxWidth: '90%', maxHeight: '90%' } }}
                 key={key}>
                    <img  className={(medien.length > 1 ? ('float-start mx-auto') : ('mx-auto'))} 
                    src={image} alt="" 
                    style={{ width: "50%", maxHeight: "600px", padding: "0px" }} 
                    />
                </Zoom>
    
            ))
            }
        </>
    )
}

export default SliderImage