import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../apiConfig';

const initialState = {
    value: {}
}

export const fetchArticleCategory = createAsyncThunk("fetchArticleCategory", async (locationID) => {
    const res = await fetch(`${apiConfig.apiBasePath}getCategory`);
    return res?.json();
});

const ArticleCategorySlice = createSlice({
  name: 'ArticleCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticleCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticleCategory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchArticleCategory.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {} = ArticleCategorySlice.actions

export default ArticleCategorySlice.reducer