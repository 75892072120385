import React, { useState, useEffect } from "react";
import CategoriesDetail from "./CategoriesDetail";
import apiConfig from "./../../apiConfig";
import { useDispatch } from "react-redux"
import { fetchArticleCategory } from "../../features/ArticleCategorySlice";

function PopularCategores() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArticleCategory());
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiConfig.apiBasePath}getCategory`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <ul className="categoriesmain d-flex justify-content-center align-items-center">
      {data &&
        data.map((item, index) => <CategoriesDetail key={index} item={item} />)}
    </ul>
  );
}

export default PopularCategores;
