import React, { useEffect, useState } from "react";
import { fetchArticle } from "../../features/ArticleSlice";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import ArticleDetail from "./ArticleDetail";
import { useParams } from "react-router-dom";
import BrandArticles from "./BrandArticles";

function Articles() {
  const dispatch = useDispatch();
  let { cat } = useParams();
  let { filter } = useParams();
  const [articlesListFilter, setArticlesListFilter] = useState();
  const locationID = useSelector((state) => state?.location?.value?.kundenid);

  useEffect(() => {
    const fetchData = async () => {
      if (locationID) {
        // Make sure locationID is not undefined before dispatching
        dispatch(fetchArticle(locationID));
        // dispatch(fetchArticleCategory());
      }
    };
    fetchData();
  }, [locationID]);

  const ArticlesList = useSelector((state) => state.ArticleSlice.value);


  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="innerPageBanner">
        <img
          className="d-none d-md-block"
          src="assets/images/Headerbanner-Geräte.jpg"
          alt=""
        />
      </section>
      <div className="categorySection">
        <div className="container">
          <div className="row">
                {Array.isArray(ArticlesList) && (
                  <BrandArticles cat={cat} ArticlesList={ArticlesList} filterSave={filter} />
                )}
              </div>
            </div>

            {/* </Slider> */}
          </div>
        
    </>
  );
}

export default Articles;