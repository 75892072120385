import { React, useEffect, useState } from 'react'
import Slider from "react-slick"

const VideoSlider = ({ videoListing,handleVdieoModel }) => {
    const [sliderVideoListing, setSliderVideoListing] = useState();
    useEffect(() => {
        const fetchData = () => {
            const filtered = videoListing.filter(item =>
                item.title.toLowerCase().includes('kennst')
            );
            filtered.slice(0, 5);
            setSliderVideoListing(filtered);
        };

        fetchData();
    }, [videoListing]);
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    }
    return (
        <section className="categorySectionSlider sliderSecond">
            <div className="container">
                <h2 className="text-light mb-3 mb-md-5">KENNST DU...?</h2>
                <div className="row">
                    <Slider {...settings} className="videoOne commonSlick">
                        {sliderVideoListing?.length > 0 ? (sliderVideoListing?.map((video, i) => (
                            <div key={i} className=" imageWrap videoWrap">
                                <img alt={video?.title} src={video?.image} onClick={() => handleVdieoModel(video?.videoId)} style={{ margin: "auto" }} />
                                {/* <iframe
                                        src={`https://www.youtube.com/embed/` + video?.videoId}
                                        frameBorder='0'
                                        allowFullScreen
                                        title='video'
                                        width={'90%'}
                                        height={'335px'}
                                    /> */}
                            </div>
                        ))) :
                            <div className=" imageWrap videoWrap">
                                <h3 className="text-white">Kein Video gefunden</h3>
                            </div>}

                    </Slider>
                    {/* {(modelVideo && (
                        <div className="modal-overlay">
                            <div className="modal" style={{ display: "block", marginTop: "200px" }}>
                                <button className="close-button" onClick={onClose} style={{ position: "absolute", zIndex: "999", width: "50px" }}>
                                    X
                                </button>
                                <iframe
                                    src={`https://www.youtube.com/embed/` + modelVideo}
                                    frameBorder='0'
                                    allowFullScreen
                                    title='video'
                                    width={'50%'}
                                    height={'600px'}
                                />
                            </div>
                        </div>))} */}

                </div>

            </div>
        </section>
    )
}

export default VideoSlider