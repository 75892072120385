import React from 'react';
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'




const layout = (props) => {
  const handleLocationChange = (value) => {
    props.cLocation(value);
};
  return (
    <>
      <header className="mainHeader">
        <Header location={handleLocationChange} />
      </header>
      <Outlet  />
      <Footer/>
    </>
    
  )
}

export default layout