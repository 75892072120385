import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../../apiConfig';

const initialState = {
    status:"loading",
    value: []
}

export const fetchUnassignedBrands = createAsyncThunk("fetchUnassignedBrands", async () => {
    const res = await fetch(`${ apiConfig.apiBasePath }getUnassignedBrands`).then(async(response)=>await response.json());
    
    // console.log(res?.json())
    return res;
 });

const unassignedBrandsSlice = createSlice({
  name: 'UnassignedBrands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnassignedBrands.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUnassignedBrands.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchUnassignedBrands.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {} = unassignedBrandsSlice.actions

export default unassignedBrandsSlice.reducer