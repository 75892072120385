import React, { useState } from "react";
import { useSelector } from 'react-redux';

const ImpressumModal = ({closeModal,isModalOpen}) => {

  const locationID = useSelector((state) => state?.location?.value?.kundenid);
  const shopLocationMap = useSelector((state) => state?.ShoplocationSlice?.value) || [];
  
  const location = Array.isArray(shopLocationMap) 
      ? shopLocationMap.find((item) => item?.locations_id === locationID) 
      : undefined;

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay DATENSCHUTZ Impressum">
        <div className="modal" style={{ display: "block", marginTop: "200px", width:"50%" }}>
          {children}
        </div>
      </div>
    );
  };

  return (
    <>
        { location && <Modal  isOpen={isModalOpen} onClose={closeModal}>
        { (
          <div
             className="col-md-8"
            style={{
              width: "100%",
              textAlign: "left",
              margin: "0 auto",
              background: "white",
              padding: "45px",
            }}
          >
             <div className="modal_head"> <button className="close-btn" onClick={closeModal} >Schließen</button></div>
            <span
              id="textdisplay"
              className="datenbereich width_100 nopadding nomargin"
            >
              
              <span className="datenbereich width_100 nopadding margin_bottom_3">
                <label className="title main top">IMPRESSUM</label>
                <div className="text-wrap first">
                    <div className="modal_block_1 modal_block">
                        <p>
                        MEDIMAX - {location.BEZEICHNUNG}<br/>
                        {location.STRASSE}<br/>
                        {location.PLZ} {location.ORT}
                        </p>
                    </div>
                    <div className="modal_block_2 modal_block">
                        <p>Vertretungsberechtige Geschäftsführer: {location.DIRECTORS}</p>
                    </div>
                    <div className="modal_block_3 modal_block">
                        <p>
                        Telefon: {location.TEL}<br/>
                        E-Mail: {location.EMAIL}
                        </p>
                    </div>
                    <div className="modal_block_4 modal_block">
                        <p>
                        Registergericht: {location.REGISTERED_COURTS}
                        </p>
                    </div>
                    <div className="modal_block_5 modal_block">
                        <p>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: {location.VAT}
                        </p>
                    </div>
                    <div className="modal_block_6 modal_block">
                    <p>
                    Inhaltlich Verantwortliche(r): {location.DIRECTORS} <br/><br/>
                    Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a><br/><br/>
                    Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                    </p>
                    </div>
                </div>
                </span>
             
            </span>
          </div>
        )}

      </Modal>
      }
      </>

  );
};

export default ImpressumModal;