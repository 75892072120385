import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import apiConfig from '../../apiConfig';
import { useNavigate  } from "react-router-dom";
import { fetchUnassignedBrands } from '../../features/PartnerList/unasssignedBrands';

function PartnersList() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchUnassignedBrands());
    },[dispatch])

    const PartnerList = useSelector((state) => state?.partner?.value);

    const unassignedBrandslist = useSelector((state) => state?.unassignedBrandsSlice?.value);
    
    

    console.log('new list', unassignedBrandslist);

    //console.log('brandImag',PartnerList);
    const goToArticle = (brand) => {
        navigate(`/artikel/-/-/${brand}`);
      };
    const goToPartner = (partner) => {
        navigate(`/partner/${partner}`);
     };

     const singleBox = (partner) =>{
        const background ={
          "background-image":`url(${apiConfig.basePath + "/assets/" + partner.main_image})`
        }
        const background2 ={
          "width":`auto`
        }
        const setBackground = partner.main_image ? background:background2;
      return(
        <>
          <div class="Prt_in_Pic" style={setBackground}>
          <div class="Prt_heading">
            <img src={partner.brandImag} alt={partner.name} />
          </div>
        </div>
        </>
      )
    
     }
  return (
    <section class="Partner_Sec">
      <div class="container">
        <div class="row">
          <div className="pageHeading"><h2>PREMIUM PARTNER</h2></div>
          </div>
          <div class="row">
            {Array.isArray(PartnerList) && PartnerList.map((partner)=>(
                  <div class="col-md-3 mb-4">
                    <a onClick={() => goToPartner(partner.slug)} href="javascript:;">

                    {singleBox(partner)}
                  
                  </a>
                </div>

            ))}
      </div>
      <div class="row new_listing">
      <div className="pageHeading"><h2>PARTNER IM ONLINESHOP</h2></div>
      </div>
      <div class="row">
            {Array.isArray(unassignedBrandslist) && unassignedBrandslist.map((Brand)=>(
                  <div class="col-md-2 mb-4">
                    <a onClick={() => goToArticle(Brand.HERSTELLER_ID)} href="javascript:;">
                  <div class="Prt_in_Pic" >
                    <div class="Prt_heading">
                      <img src={Brand.MEDIENPFAD} alt={Brand.BEZEICHNUNG} />
                    </div>
                  </div>
                  </a>
                </div>

            ))}
      </div>
	  
      </div>
    </section>
  );
}

export default PartnersList;
