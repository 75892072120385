import React from 'react'

function CategorySearch({searchCatTerm,handleSearchCatChange,uniqueCat,catFilters,handleCatCheckboxChange}) {
  return (
    <div className="cardAreaInner">
                  <div className="form-group">
                    <label>Kategorie</label>
                    <input
                      type="text"
                      value={searchCatTerm}
                      onChange={handleSearchCatChange}
                      placeholder="Suche Kategorie"
                      className="form-control"
                      style={{ width: "90%" }}
                    />
                  </div>
                  <div className="cardAreaInner productInner brand-list-container">
                    <ul className="brand-list">
                      {Array.isArray(uniqueCat) &&
                        uniqueCat.map((Article, index) => (
                          <li key={index}>
                            <label>
                              <input
                                type="checkbox"
                                checked={catFilters.includes(Article.id)}
                                onChange={() =>
                                  handleCatCheckboxChange(Article.id)
                                }
                              />
                              {Article.cat}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
  )
}

export default CategorySearch