import React from 'react'

function BrandSearch({searchTerm,handleSearchChange,handleBrandCheckboxChange,uniqueBrand,brandFilters,selectedBrand}) {
  return (
    <div className="cardAreaInner">
                  <div className="form-group">
                    <label>Marke</label>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Marke suchen"
                      className="form-control"
                      style={{ width: "90%" }}
                    />
                  </div>
                  <div className="cardAreaInner productInner brand-list-container">
                    <ul className="brand-list">
                      {Array.isArray(uniqueBrand) &&
                        uniqueBrand.map((Article, index) => (
                          Article.brandName && <li key={index}>
                            <label>
                              <input
                                type="checkbox"
                                checked={brandFilters.includes(Article.id) || selectedBrand.includes(Article.id) }
                                onChange={() =>
                                  handleBrandCheckboxChange(Article.id)
                                }
                              />
                              {Article.brandName}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
  )
}

export default BrandSearch