import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../apiConfig';

const initialState = {
    value: {}
}

export const fetchArticle = createAsyncThunk("fetchArticle", async (locationID) => {
  const res = await fetch(`${apiConfig.apiBasePath}getArticel`, {
    method: 'POST',
    body: JSON.stringify({
      locationID:locationID
    })
});

  
  // Check if the response is ok
  if (!res.ok) {
      throw new Error('Network response was not ok');
  }

  return res.json();
});

const ArticleSlice = createSlice({
  name: 'Article',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchArticle.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {} = ArticleSlice.actions

export default ArticleSlice.reducer