import React, { useState, useEffect } from "react";

function CollageImages({ Article, count }) {
  const [images, setImages] = useState(null);

  useEffect(() => {
    if (Article?.images != null) {
      setImages(Article?.images.split(","));
    } else {
      setImages(["../assets/images/nproduct.png no-images"]);
    }
  }, [Article]);

  return (
    <>
      {images && (
        <img
          className="mb-3"
          src={images[0]}
          alt=""
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50px",
            background: "white",
          }}
        />
      )}
    </>
  );
}

export default CollageImages;
