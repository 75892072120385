import React, { useState, useEffect } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import BrandSearch from "./BrandSearch";
import CategorySearch from "./CategorySearch";

function ArticlesSearch({
  minPrice,
  maxPrice,
  handleFilterChange,
  filter,
  handleInputPrice,
  currentItems,
  handleBrandFilter,
  handleCatCheckboxChange,
  brandFilters,
  catFilters,
  filteredArticles,
  resetFilters,
  brand,
  setCatFilters
}) {
  const [uniqueBrand, setUniqueBand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [uniqueCat, setUniqueCat] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCatTerm, setSearchCatTerm] = useState("");



  useEffect(() => {
  
      const searchTerms = filter.toLowerCase().split(" ");
  
      const filteredArticlestext = filteredArticles.length > 0 ? currentItems.filter(product =>
          searchTerms.every(term =>
              product.BESCHREIBUNG_KURZ_ORIGINAL.toLowerCase().includes(term) ||
              product.BEZEICHNUNG.toLowerCase().includes(term) ||
              product.ARTIKELNR.toLowerCase().includes(term) ||
              product.EAN.toLowerCase().includes(term) ||
              product.BESCHREIBUNG_LANG.toLowerCase().includes(term) ||
              (product.cat && product.cat.toLowerCase().includes(term)) ||
              (product.brandName && product.brandName.toLowerCase().includes(term))
          ) 
      ): currentItems;
      // {
    //     rturn false;
    //   }
    //   return true;
    // }) : currentItems;

    const brandName = filteredArticlestext.reduce((acc, current) => {
      const x = acc.find((item) => item.brandName === current.brandName);
      if (!x) {
        return acc.concat([
          { id: current.HERSTELLER_ID, brandName: current.brandName },
        ]);
      } else {
        return acc;
      }
    }, []);
    setUniqueBand(brandName);

    const filteredItems = currentItems.filter((item) =>
      brandFilters.includes(item.HERSTELLER_ID)
    );

    const catName = filteredItems.reduce((acc, current) => {
      const x = acc.find((item) => item.cat === current.cat);
      if (!x) {
        return acc.concat([{ id: current.WARENGRUPPEN_ID, cat: current.cat }]);
      } else {
        return acc;
      }
    }, []);
    if(brand)
        setCatFilters(catName.map((cat)=>cat.id));
    setUniqueCat(catName);

    if (searchTerm) {
      const brandNameFilter = uniqueBrand.filter((brand) => {
        // Check if brandName exists and includes searchTerm (case-insensitive)
        return (
          brand.brandName &&
          brand.brandName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setUniqueBand(brandNameFilter);
    }

    if(searchCatTerm){
      const catNameFilter = uniqueCat.filter((cat) => {
        // Check if brandName exists and includes searchTerm (case-insensitive)
        return (
          cat.cat &&
          cat.cat.toLowerCase().includes(searchCatTerm.toLowerCase())
        );
      });
      setUniqueCat(catNameFilter);
    }

    
  }, [filter, currentItems, searchTerm, brandFilters,searchCatTerm]);

  const handleBrandCheckboxChange = (brand) => {
    handleBrandFilter(brand);
  };

  const handleSearchChange = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchTerm(search);
  };

  const handleSearchCatChange = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchCatTerm(search);
  };

  return (
    <>
      <form className="searchForm pb-5 text-end d-flex justify-content-end">
        <input
          type="text"
          name="Suche"
          placeholder="Bezeichnung, Marke, Funktion"
          value={filter}
          onChange={handleFilterChange}
        />
        <input type="submit" value="Suche" disabled />
      </form>
      <div className="col-md-3">
        <div className="search-bar">
          <div className="imageWrap">
            <div className="cardAreaInner productInner">
              <form style={{ width: "100%" }}>
                <div className="form-group">
                  <label>Preisspanne:</label>
                  <MultiRangeSlider
                    min={0}
                    max={10000}
                    step={50}
                    minValue={minPrice}
                    maxValue={maxPrice}
                    barInnerColor="rgb(209 48 30)"
                    ruler={false}
                    label={false}
                    className="cstm_range_slider"
                    thumbLeftColor="#e6e6e6"
                    thumbRightColor="#e6e6e6"
                    minCaption={minPrice}
                    onInput={(e) => {
                      handleInputPrice(e.minValue, e.maxValue);
                    }}
                  />
                 {minPrice > 0 && (<div className="min_max_value" > <span>{minPrice}&#8364;</span> - <span>{maxPrice}&#8364;</span></div>)}
                </div>

                <BrandSearch
                  searchTerm={searchTerm}
                  handleSearchChange={handleSearchChange}
                  handleBrandCheckboxChange={handleBrandCheckboxChange}
                  uniqueBrand={uniqueBrand}
                  brandFilters={brandFilters}
                  selectedBrand={selectedBrand}
                />
                <CategorySearch
                searchCatTerm={searchCatTerm}
                handleSearchCatChange={handleSearchCatChange}
                  uniqueCat={uniqueCat}
                  catFilters={catFilters}
                  handleCatCheckboxChange={handleCatCheckboxChange}
                />
                <button type="button" onClick={resetFilters} style={{ background:"#FD0", borderRadius: "5px", float:"left" }} > Filter zurücksetzen </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArticlesSearch;
