import React, { useEffect } from "react";
import apiConfig from "../../apiConfig";

function DynamicCSSComponent({ partnerID }) {
  // async function fetchDynamicCSS() {
  //     try {
  //         const response = await fetch('https://example.com/api/css'); // Replace with your API endpoint
  //         if (!response.ok) {
  //             throw new Error('Failed to fetch CSS');
  //         }
  //         return await response.text();
  //     } catch (error) {
  //         console.error('Error fetching CSS:', error);
  //         return '';
  //     }
  // }

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = `${apiConfig.basePath}/assets/css/partner_${partnerID}.css`;

    document.head.appendChild(link);

    // Cleanup function to remove the stylesheet when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [partnerID]);
}

export default DynamicCSSComponent;
