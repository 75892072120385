import React, { useState, useEffect } from "react";
import ArticleDetail from "./ArticleDetail";
import VideoPagination from "../VideoLibrary/VideoPagination";
import ArticlesSearch from "./ArticlesSearch";
import { useParams, useSearchParams } from "react-router-dom";

function BrandArticles({ ArticlesList, cat, filterSave }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(21); // Number of items per page
  const [filter, setFilter] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("10000");
  const [brandFilters, setBrandFilters] = useState([]);
  const [catFilters, setCatFilters] = useState([]);
  let { brand } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  
  

  window.addEventListener("beforeunload", () => {
    resetFilters();
  });

  const resetFilters = () => {
    setFilter("");
    setMinPrice(0);
    setMaxPrice(10000);
    setBrandFilters([]);
    setCatFilters([]);
    setFilteredArticles(ArticlesList); // Reset to original list
    localStorage.removeItem("filter");
    localStorage.removeItem("brandFilters");
    localStorage.removeItem("catFilters");
    localStorage.removeItem("minPrice");
    localStorage.removeItem("pageNumber");
  };

  const handleCatCheckboxChange = (cat) => {
    const newCatFilters = [...catFilters];
    if (newCatFilters.includes(cat)) {
      newCatFilters.splice(newCatFilters.indexOf(cat), 1);
    } else {
      newCatFilters.push(cat);
    }
    setCatFilters(newCatFilters);
    applyFilters();
  };

  const applyFilters = () => {
    let filteredList = [...ArticlesList];

    // Apply category filter
    if (catFilters.length > 0) {
      filteredList = filteredList.filter((product) =>
        catFilters.includes(product.WARENGRUPPEN_ID)
      );
    }

    // Apply brand filter
    if (brandFilters.length > 0) {
      filteredList = filteredList.filter((product) =>
        brandFilters.includes(product.HERSTELLER_ID)
      );
    }

    // Apply price range filter
    if (minPrice !== "" && maxPrice !== "") {
      filteredList = filteredList.filter(
        (product) =>
          product.VK >= parseFloat(minPrice) &&
          product.VK <= parseFloat(maxPrice)
      );
    }

    // Apply search filter
    if (filter !== "") {
      const searchTerms = filter.toLowerCase().split(" ");

      filteredList = filteredList.filter((product) =>
        searchTerms.every(
          (term) =>
            product.BESCHREIBUNG_KURZ_ORIGINAL.toLowerCase().includes(term) ||
            product.BEZEICHNUNG.toLowerCase().includes(term) ||
            product.ARTIKELNR.toLowerCase().includes(term) ||
            product.EAN.toLowerCase().includes(term) ||
            product.BESCHREIBUNG_LANG.toLowerCase().includes(term) ||
            (product.cat && product.cat.toLowerCase().includes(term)) ||
            (product.brandName &&
              product.brandName.toLowerCase().includes(term))
        )
      );
    }

    // Update filtered articles state
    setFilteredArticles(filteredList);

    // Save filter settings to localStorage
    localStorage.setItem("filter", JSON.stringify(filter));
    localStorage.setItem("brandFilters", JSON.stringify(brandFilters));
    localStorage.setItem("catFilters", JSON.stringify(catFilters));
    localStorage.setItem("minPrice", JSON.stringify(minPrice));
    localStorage.setItem("maxPrice", JSON.stringify(maxPrice));
  };
console.log(catFilters)
  // Initialize filter values from localStorage on component mount
  useEffect(() => {
   
    if (filterSave != 1 && filterSave == undefined && (cat != 0 || cat == undefined)) {
      resetFilters();
    } else {
      const savedFilter = localStorage.getItem("filter");
      const savedBrandFilters = localStorage.getItem("brandFilters");
      const savedCatFilters = localStorage.getItem("catFilters");
      const savedMinPrice = localStorage.getItem("minPrice");
      const savedMaxPrice = localStorage.getItem("maxPrice");
      const savePageNumber = localStorage.getItem("pageNumber");

      if (savedFilter) setFilter(JSON.parse(savedFilter));
      if (savedBrandFilters) setBrandFilters(JSON.parse(savedBrandFilters));
      if (savedCatFilters) setCatFilters(JSON.parse(savedCatFilters));
      if (savedMinPrice) setMinPrice(JSON.parse(savedMinPrice));
      if (savedMaxPrice) setMaxPrice(JSON.parse(savedMaxPrice));
      if (savePageNumber) setCurrentPage(JSON.parse(savePageNumber));

      if (brand) setBrandFilters([brand]);

    }
    if( searchParams.get("search"))
      setFilter(searchParams.get("search"));

    if (cat != 0 && cat != undefined) {
      handleCatCheckboxChange(cat);
    }

    // applyFilters();
  }, [filterSave, cat]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    localStorage.setItem("pageNumber", JSON.parse(pageNumber));
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleInputPrice = (min, max) => {
    setMaxPrice(isNaN(max) ? "" : max);
    setMinPrice(isNaN(min) ? "" : min);
    applyFilters();
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredArticles.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleBrandFilter = (brand) => {
    const newBrandFilters = [...brandFilters];
    if (newBrandFilters.includes(brand)) {
      newBrandFilters.splice(newBrandFilters.indexOf(brand), 1);
    } else {
      newBrandFilters.push(brand);
    }
    setBrandFilters(newBrandFilters);
    applyFilters();
  };

  return (
    <>
      {ArticlesList && (
        <ArticlesSearch
          minPrice={minPrice}
          maxPrice={maxPrice}
          handleFilterChange={handleFilterChange}
          filter={filter}
          handleInputPrice={handleInputPrice}
          currentItems={ArticlesList}
          handleBrandFilter={handleBrandFilter}
          handleCatCheckboxChange={handleCatCheckboxChange}
          brandFilters={brandFilters}
          catFilters={catFilters}
          filteredArticles={filteredArticles}
          resetFilters={resetFilters}
          brand = {brand}
          setCatFilters= {setCatFilters}

        />
      )}
      <div class="col-md-9">
        <div class="row">
          {Array.isArray(currentItems) &&
            currentItems.map((Article, index) => (
              <ArticleDetail key={index} Article={Article} cat={cat} />
            ))}
        </div>
      </div>
      <VideoPagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredArticles.length}
        onPageChange={paginate}
      />
    </>
  );
}

export default BrandArticles;
