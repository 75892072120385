import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"

function ParnerarticleDetail({Article}) {

    const [images, setImages] = useState([]);

  useEffect(() => {
    if ( Article?.images != null) {
      setImages(Article?.images.split(','));
    }else{
      setImages([]);
    }
  },[Article]);
  return (
    <div className="imageWrap">
    <div className="cardAreaInner productInner">
    <NavLink to={`/ProductDetailPage/${Article.ARTIKELNR}` }>
        {images.length > 0 ? ( <img src={images[0].includes('https') ? images[0] : `https://onepas.de/v3/msmedia/${images[0]}`} alt=""  />) : <img src="../assets/images/no-image.jpg" />}
        <h5 className="mb-3">{Article.BEZEICHNUNG}</h5>
        <div className="priceCard">{Article.VK} €</div>
        <button className="reserveButton">RESERVIEREN</button>
    </NavLink>    
    </div>
  </div>
  )
}

export default ParnerarticleDetail