import React from 'react';
import Slider from "react-slick";
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import axios from '../../axiosConfig'

const ProductSlider = (props) => {

    const currentLoctionId = useSelector((state) => state?.location?.value?.kundenid);

    const settings = {
        arrows: true,
        dots: false,
        centerPadding: '5px',
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    }
    const CheckImage = async (path) => {
        try{
            await axios.get(path)
            return true;
        } catch (error) {
           return false;
        }
    }

    return (
        <>
            {props?.Article?.data && (props?.Article?.data.length > 2) && (
                <Slider {...settings} className="commonSlick">
                    {props?.Article?.data.map((items) => (                        
                        <div key={items.id} className="imageWrap">
                            <NavLink to={'/werbung/'+currentLoctionId+'/'+items.id}><img src={items.medien[0]} alt="" /></NavLink>
                        </div>
                        // )
                    ))}
                </Slider>
            ) }
            {props?.Article?.data && (props?.Article?.data.length < 3) && (
               <div className='col-md-12'>
                    {props?.Article?.data.map((items) => (
                        <div key={items.id} className="w-25 p-3" style={{ float: 'left' }}>
                           <NavLink to={'/werbung/' +items.id}><img src={items.medien} alt="" style={{ padding : "20px" }} /></NavLink> 
                        </div>
                    ))}
                </div>
            ) }
        </>
    )
}

export default ProductSlider