import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"

function ArticleDetail({Article, cat}) {
  const [images, setImages] = useState(null);
  const [imagesJpg, setImagesJpg] = useState([]);

  useEffect(() => {
    if (Article?.images != null) {
      const imageArray = Article.images.split(',');
      setImages(imageArray);

      const jpgImg = imageArray.filter((image) => image.toLowerCase().endsWith(".jpg"));
      if( jpgImg.length >0 ){
        setImagesJpg(jpgImg);
      }else{
        setImagesJpg(imageArray.filter((image) => image.toLowerCase().endsWith(".png")));
      }
      
    } else {
      // Handle case where Article.images is null or undefined
      setImages([]);
      setImagesJpg([]);
    }
  }, [Article]); 
  // .filter((image) => image.endsWith(".jpg"))

 
  return (
    <div className="col-md-4 mb-4">

    <div className="imageWrap">
    <div className="cardAreaInner productInner">
    <NavLink to={`/ProductDetailPage/${Article.ARTIKELNR}` }>
        {imagesJpg.length > 0 && <img src={imagesJpg[0].includes('https') ? imagesJpg[0] : `https://onepas.de/v3/msmedia/${imagesJpg[0]}`} alt=""  />}
        <h5 className="mb-3">{Article.BEZEICHNUNG}</h5>
        <span>{Article.brandName}</span>
        <div className="priceCard">{Article.VK} €</div>
        <button className="reserveButton">RESERVIEREN</button>
    </NavLink>    
    </div>
  </div>
  </div>
  )
}

export default ArticleDetail