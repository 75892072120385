import React,{ useState,useEffect } from 'react'
import Slider from "react-slick"
import { useNavigate  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"

function Partnerslider() {
    const navigate = useNavigate();
    const [partnerSort, setPartnerSort] = useState(null);

    const PartnerList = useSelector((state) => state?.partner?.value);

    const shuffleArray = (array) => {
        // Create a copy of the array to avoid mutating the original array
        let shuffledArray = array.slice();
        
        // Iterate backwards through the array
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          // Generate a random index between 0 and i (inclusive)
          const j = Math.floor(Math.random() * (i + 1));
          
          // Swap the elements at indices i and j
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        setPartnerSort(shuffledArray)
        return shuffledArray;
    }
    useEffect(() => {
        {Array.isArray(PartnerList) && shuffleArray(PartnerList)}
    }, [PartnerList]);
    

    const goToArticle = (brand) => {
        navigate(`/artikel/-/-/${brand}`);
      };
    const goToPartner = (partner) => {
        navigate(`/partner/${partner}`);
     };

    
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    arrows: false

                }
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    arrows: false

                }
            }]

    }
    return (
        <Slider {...settings} className="sliderCategories_third commonSlick">

{Array.isArray(partnerSort) && partnerSort.map((partner)=>(
                  <div class="imageWrap">
                    <a onClick={() => goToPartner(partner.slug)} href="javascript:;">
                    <img src={partner.brandImag} alt={partner.name} />
                  </a>
                </div>

            ))}
            {/* <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_AEG.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_AVM.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Bauknecht.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Beurer.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Bosch.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Caso.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Graef.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Grundig.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_hama.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_hd+.png`} alt="" />
            </div>


            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_jura.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Kärcher.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Kitchenaid.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Leifheit.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Miele.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Moak.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Neff.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Siemens.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Sodastream.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Techfant.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Technisat.png`} alt="" />
            </div>

            <div className="imageWrap">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Bubble_Telekom.png`} alt="" />
            </div> */}

        </Slider>
    )
}

export default Partnerslider