import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiConfig from '../../apiConfig';

const initialState = {
    value: {}
}

export const fetchVideo = createAsyncThunk("fetchVideo", async () => {
    const res = await fetch(`${apiConfig.apiBasePath}getVideo`);
    return res?.json();
 });

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVideo.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchVideo.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {} = videoSlice.actions

export default videoSlice.reducer