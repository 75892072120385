import React,{useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

function ActionSlider() {

    const [sliderList, setSliderList] = useState(null);
    const topSliderSlice = useSelector((state) => state?.topSliderSlice?.value);
  
    useEffect(() => {
      Array.isArray(topSliderSlice) && setSliderList(topSliderSlice);
    }, [topSliderSlice]);

    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    }
    return (
        <Slider {...settings} className="process.env.PUBLIC_URL}sliderCategories_second commonSlick">
            {sliderList && sliderList.map((slider) => (
        <div className="slide" key={slider.id}> {/* Add a unique key for each slide */}
          <a href={slider.link} target="_blank" rel="noopener noreferrer"> {/* Fixed target attribute and added rel for security */}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${slider.small_image}`}
              alt={slider.image_name || 'Slide image'} 
              style={{ maxHeight: "578px" }}
            />
          </a>
        </div>
      ))}
        </Slider>
    )
}

export default ActionSlider