import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import videoSlice from '../features/videoYoutube/videoSlice';
import locationSlice from '../features/shopLocation/locationSlice';
import ShoplocationSlice from '../features/shopLocation/ShoplocationSlice';
import topSliderSlice from '../features/shopLocation/topSliderSlice';
import partnerSlice from '../features/PartnerList/partnerSlice';
import ArticleSlice from '../features/ArticleSlice';
import ArticleCategorySlice from '../features/ArticleCategorySlice';
import unassignedBrandsSlice from '../features/PartnerList/unasssignedBrands';

const saveToLocalStorage = (state : any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.warn('Could not save state', e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn('Could not load state', e);
    return undefined;
  }
};


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    video: videoSlice,
    location: locationSlice,
    partner: partnerSlice,
    ArticleSlice: ArticleSlice,
    ArticleCategorySlice: ArticleCategorySlice,
    ShoplocationSlice: ShoplocationSlice,
    topSliderSlice: topSliderSlice,
    unassignedBrandsSlice: unassignedBrandsSlice
  },
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
