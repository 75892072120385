import React from 'react';
import { useSelector } from 'react-redux';

function LocationMap() {
  const locationID = useSelector((state) => state?.location?.value?.kundenid);
  const shopLocationMap = useSelector((state) => state?.ShoplocationSlice?.value);

  // console.log("shopLocationMap",typeof (shopLocationMap));

  // let location = shopLocationMap.find((item) => item.locations_id === locationID);

  const location = Array.isArray(shopLocationMap) 
  ? shopLocationMap.find((item) => item?.locations_id === locationID) 
  : undefined;

 


  return (
    <>
   { location ? (
      <iframe
        src={location.map}
        title="360 View here"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    ) : null
}
    
    </>
  );
}

export default LocationMap;