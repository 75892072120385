import React, { useState } from "react";
import { useCookies } from "react-cookie";

const CookiesBanner = () => {
  const [cookies, setCookie] = useCookies(["cookiesAccepted"]);
  const [isVisible, setIsVisible] = useState(!cookies.cookiesAccepted);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showSetting, setShowSetting] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: cookies.analyticsConsent,
    marketing: cookies.marketingConsent,
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };


  const handleSave = () => {
    if (preferences.analytics) {
      setCookie('analyticsConsent', 'true');
    } 

    if (preferences.marketing) {
      setCookie('marketingConsent', 'true');
    } 
    setCookie("cookiesAccepted", true, { path: "/" });
    setIsVisible(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const acceptCookies = () => {
    setCookie("cookiesAccepted", true, { path: "/" });
    setIsVisible(false);
  };

  const showSettings = () => {
    setShowSetting(true);
  };

  if (!isVisible) {
    return null;
  }

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay cookies_popup">
        <div className="modal" style={{ display: "block", marginTop: "0px", width:"100%" }}>
          {children}
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {!showSetting && (
          <div
            class="col-md-8"
            style={{
              width: "100%",
              textAlign: "left",
              margin: "0 auto",
              background: "white",
              padding: "45px",
            }}
          >
            <span
              id="textdisplay"
              class="datenbereich width_100 nopadding nomargin"
            >
              <span class="datenbereich width_100 nopadding margin_bottom_3">
                <label class="title nomargin">Ihre Cookie Einstellungen</label>
                <label class="text">
                  <p>
                    Diese Webseite verwendet Cookies und ähnliche Technologien.
                    Mit einem Klick auf "Zustimmen" akzeptieren Sie diese
                    Verarbeitung und auch die Weitergabe Ihrer Daten an
                    Drittanbieter. Die Daten werden für Analysen, Statistiken
                    und Werbung auf unseren Seiten, sowie auf
                    Drittanbieterseiten genutzt. Weitere Informationen finden
                    Sie in den Einstellungen sowie in unseren{" "}
                    <span className="text-decoration-underline" type="button" onclick="getDatenschutz();">
                      Datenschutzhinweisen
                    </span>
                    .
                  </p>
                  <p>
                    Sie können die Verwendung von Cookies{" "}
                    <a type="button" onclick="setRequiredBanner();">
                      ablehnen
                    </a>{" "}
                    oder jederzeit über Ihre{" "}
                    <span className="text-decoration-underline" type="button" onClick={showSettings}>
                      Einstellungen
                    </span>{" "}
                    anpassen.
                  </p>
                </label>
              </span>
              <span class="datenbereich width_100 nopadding nomargin">
                <span class="gridwrapper width_100 nopadding nomargin">
                  <button onClick={acceptCookies}>ZUSTIMMEN</button>
                </span>
              </span>
            </span>
          </div>
        )}

        {showSetting && (
          <div
            class="col-md-8"
            style={{
              width: "100%",
              textAlign: "left",
              margin: "0 auto",
              background: "white",
              padding: "45px",
            }}
          >
            <span class="float-start w-100 p-0 m-0">
              <label class="title m-0">
                Verwalten Sie Ihre Cookie Einstellungen
              </label>
              <label class="text">
                <p>
                  Um Ihnen ein optimales Webseiten Erlebnis zu bieten, setzen
                  wir Cookies ein. Das sind kleine Textdateien, die auf Ihrem
                  Computer gespeichert werden. Dazu zählen Cookies für den
                  Betrieb und die Optimierung der Seite als auch für Services,
                  wie die Erfassung von Statistiken, Analysen und Werbung. Wir
                  möchten Ihnen die Wahl geben, welche Cookies Sie zulassen:
                </p>
              </label>
            </span>
            <span class="float-start w-100 p-0 m-0">
              <form
                id="form-cookieBanner"
                name="formCookieBanner"
                onsubmit="return false;"
              >
                <span class="float-start w-100 p-0 mb-3">
                  {" "}
                  <span class="float-start cookiebannerElement_width cookiebannerElement_fullwidth p-3 m-0">
                    <input
                      type="checkbox"
                      id="cb_cookiebanner_Benötigt"
                      name="necessary"
                      value="1"
                      checked={preferences.necessary}
                      disabled
                    />
                    <label class="checkboxtext" for="cb_cookiebanner_Benötigt">
                      Benötigt
                    </label>
                  </span>{" "}
                  <span class="float-start cookiebannerElement_width cookiebannerElement_fullwidth p-3 m-0">
                    <input
                      type="checkbox"
                      id="cb_cookiebanner_Statistiken"
                      name="analytics"
                      checked={preferences.analytics}
                      onChange={handleChange}
                    />
                    <label
                      class="checkboxtext"
                      for="cb_cookiebanner_Statistiken"
                    >
                      Statistiken
                    </label>
                  </span>{" "}
                  <span class="float-start cookiebannerElement_width cookiebannerElement_fullwidth p-3 m-0">
                    <input
                      type="checkbox"
                      id="cb_cookiebanner_Marketing"
                      name="marketing"
                      checked={preferences.marketing}
                      onChange={handleChange}
                    />
                    <label class="checkboxtext" for="cb_cookiebanner_Marketing">
                      Marketing
                    </label>
                  </span>{" "}
                </span>
                <span class="float-start w-100 p-0 m-0">
                  <input
                    type="button"
                    id="setbanner"
                    name="setbanner"
                    value="SPEICHERN"
                    onClick={handleSave}
                  />
                </span>
              </form>
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CookiesBanner;
