import React, { useEffect, useState } from "react";
import apiConfig from "../../apiConfig";
import PartnerArticlesSlider from "./PartnerArticlesSlider";

function PartnerArticles({ partner_detail }) {
  const [partnerArticle, setPartnerArticle] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiConfig.apiBasePath}getArticelByBrand`,
          {
            method: "POST",
            body: JSON.stringify({
              Brandname: decodeURIComponent(partner_detail.brand),
              Categoryname: decodeURIComponent(partner_detail.category),
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPartnerArticle(data); // Use the response data as needed
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
      }
    };
    fetchData();
  }, [partner_detail]);

  const getBrandList = (array, field) => {
    const seen = new Set();
    return array.filter((item) => {
      const isDuplicate = seen.has(item[field]);
      seen.add(item[field]);
      return !isDuplicate;
    });
  };

  return (
    <section className="product_cat_slider sliderNeffProduct py-5 ">
      <div className="container py-4 my-2">
        {/* {partnerArticle?.length > 0 && (
          <h2 className="text-light mb-3 mb-md-5">
            {decodeURIComponent(partner_detail.name)} PRODUKTE
          </h2>
        )} */}
        {partnerArticle?.length > 0 &&
          getBrandList(partnerArticle, "WARENGRUPPEN_ID").map((item) => (
            <>
              <h2 className="text-light mb-3 mb-md-5 text-start">
                {decodeURIComponent(partner_detail.name)} {item.cat} PRODUKTE
              </h2>
              <div className="product_slider commonSlick" id="neffProduct">
                {partnerArticle?.length > 0 && (
                  <PartnerArticlesSlider partnerArticle={partnerArticle} ArticleCat = { item.WARENGRUPPEN_ID } />
                )}
              </div>
            </>
          ))}
      </div>
    </section>
  );
}

export default PartnerArticles;
