import React from 'react'
import ArticleDetail from "../Articles/ArticleDetail";
import Slider from "react-slick";
import ParnerarticleDetail from "./ParnerarticleDetail";

function PartnerArticlesSlider({partnerArticle, ArticleCat}) {
  let count = 0;
  if(partnerArticle){
    count = partnerArticle.filter((items) => items.WARENGRUPPEN_ID == ArticleCat).length
  }

    const settings = {
        arrows: true,
        dots: false,
        infinite: count > 4,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <Slider {...settings} className="videoOne commonSlick">
            {partnerArticle &&
              partnerArticle.filter((items) => items.WARENGRUPPEN_ID == ArticleCat).map((Article, index) => (
                <ParnerarticleDetail Article={Article}  key={index} />
              ))}
          </Slider>
  )
}

export default PartnerArticlesSlider