import React, { useEffect, useState } from 'react'
import { connect,useSelector } from 'react-redux'
import ProductSlider from './ProductSlider';
import { useParams,NavLink  } from 'react-router-dom'
import LoopSlider from './LoopSlider';
import SingleSlider from './SingleSlider';
import apiConfig from "../../apiConfig";
import { fetchLocationInfo } from '../../features/shopLocation/locationSlice';

export const AktuelleWerbung = (props) => {
  const { itemsId } = useParams();
  const { locationId } = useParams();
  const [location, setLocation] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(useSelector((state) => state?.location?.value?.kundenid));

  const { protocol, hostname, port } = window.location;
  const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
  const locationID = useSelector((state) => state?.location?.value?.kundenid);

  useEffect(() => {  
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiConfig.apiBasePath}locationList`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setLocation(result);
        // console.log(result,"locationaa")
        // console.log(result.filter((loc) => loc.locations_id == locationId,"location123a"))
        if (locationId !== undefined && locationId !== null) { 
          props.updateShop(result.filter((loc) => loc.locations_id == locationId )[0]);
        } else if (currentLocation) {
          props.updateShop(result.filter((loc) => loc.locations_id == locationId )[0]);
        }
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();

  }, [currentLocation, locationId]);


  let shareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://technikkenner.de/technikkenner_api/meta/werbung/39/${locationID}`;
    
  // Append itemsId only if it's defined
  if (itemsId !== undefined && itemsId !== null) {
      shareUrl += `/${itemsId}`;
  }


  const shopLocation = props.value;

  let itemsList = shopLocation?.data;
  let itemsClass = 'col-md-3'
  let imgClass = '50%'

  if (shopLocation?.data) {
    itemsList = (itemsId) ? shopLocation?.data.filter((item) => item.id === itemsId) : shopLocation?.data;
    itemsClass = (itemsId) ? 'col-md-12' : 'col-md-3';
    imgClass = (itemsId) ? '50%' : '65%';
  }

  const settings = {
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const imageList = [];
  let imageMerge = [];

  if (itemsId && itemsList?.length > 0) {
    itemsList?.map((items, i) => {
      items?.medien.map((medien, i) => {
        if (i === 0 || i === items.medien.length - 1) {
          imageMerge = [];
          imageMerge.push(medien);
          imageList.push(imageMerge);
        } else {
          if (i % 2 !== 0) {
            imageMerge = [];
          }
          imageMerge.push(medien);
          if (i % 2 === 0) {
            imageList.push(imageMerge);
          }
        }
        return true;
      })
      return true;
    })
    
  }

  let shreURl =   `${baseUrl}${process.env.PUBLIC_URL}/werbung/${locationID}`
  
  if (itemsId !== undefined && itemsId !== null) {
    shreURl += `/${itemsId}`;
}

  const body = `Moin Moin\n\n  anbei ein Angebot von  M.S. Media Electronic Duderstadt GmbH:\n\n${shreURl} \n\n Klingt interessant und musste an dich denken. \n\n Lieben Gruß \n \n Dies ist ein kostenloser Service von WOCHENHIGHLIGHT.DE – Alle Prospekte kostenfrei online. Sent from Mail for Windows`;

  const mailtoLink = `mailto:?subject=${encodeURIComponent('Angebote KW26')}&body=${encodeURIComponent(body)}`;




  return (
    <>
      <section className="innerPageBanner">
        <img src="/assets/images/Headerbanner-Prospekte.jpg" alt="" />
      </section>
      <div className="innepageBody">
        <div className="catelogSection">
          <div className="container">
            <div className="card catelogCard">
              <div className="card-body">
                <h2 className="card-title text-light m-0">UNSERE AKTUELLEN ANGEBOTE</h2>
                <div className="cardDetails">
                  {itemsList && (
                    <div className='row'>
                      {itemsId && itemsList.map((items, i) => (
                        items.medien.length < 2 ?
                          (<div key={items.id} className={itemsClass} >
                            <NavLink to={'/werbung/'+locationID+'/' + items.id}><img src={items.medien[0]} alt="" style={{  width: imgClass }} /></NavLink>

                          </div>) : (imageList.length > 0 && (
                            <SingleSlider imageList={imageList} settings={settings} key={items.id + 'ch'} />

                          ))
                      ))}
                      

                      
                        {!itemsId && itemsList.map((items, i) => (
                          <div key={items.id} className={itemsClass} >
                            <NavLink to={'/werbung/'+locationID+'/' + items.id}><img src={items.medien[0]} alt="" style={{  width: imgClass }} /></NavLink>

                          </div>
                        ))}
                    <div className="nav-slider">  
                     { (itemsId) &&
                      <span className="zurueckbutton_container_wh">
                      <NavLink to={'/werbung'} ><span className="zurueckbutton_wh"  title="Zurück zur Auswahl"></span></NavLink>
                      </span>
}                    
                      <span className="fbshare_icon_container_wh">
                        <a href={shareUrl} className="a_wh" title="Auf Facebook teilen" alt="Auf Facebook teilen" target='_blank' >
                          <span className="fbshare_icon_wh"></span>
                        </a>
                        
                      </span>
                      <span className="whashare_icon_container_overview_wh">
                        <a href={"https://api.whatsapp.com/send?text=Mega%20Angebot.%20Vielleicht%20was%20für%20dich:%20"+shreURl+""} className="a_wh" title="Auf WhatsApp teilen" alt="Auf WhatsApp teilen" target="_blank">
                          <span className="whashare_icon_wh"></span>
                        </a>
                      </span>
                      <span className="mailshare_icon_container_wh">
                        <a href={mailtoLink} className="a_wh" title="Als E-Mail teilen" alt="Als E-Mail teilen">
                          <span className="mailshare_icon_wh"></span>
                        </a>
                      </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ProductSlider /> */}
      </div>
    </>
  )
}



const mapStateToProps = (state) => ({
  value: state.location.value
})

const mapDispatchToProps = dispatch => ({
  updateShop: (shopLocation) => dispatch(fetchLocationInfo(shopLocation))
});

export default connect(mapStateToProps, mapDispatchToProps)(AktuelleWerbung)