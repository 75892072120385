import React from 'react'

const VideoSearch = ({handleSearch}) => {
    return (
        <form className="searchForm pb-5 text-end d-flex justify-content-end">
            <input type="text" name="search"  onChange={(e) => handleSearch(e.target.value)} />
            <input type="submit"  value="Search" />
        </form>
    )
}

export default VideoSearch