import React from "react";

const VideoPagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (pageNumber) => {
    onPageChange(pageNumber);
  };
  return (
    <div className="paginationMain pb-5 bg-dark-new">
      <div className="container">
        <div className="row justify-content-center">
          <nav aria-label=" navigation w-auto">
            <ul className="pagination justify-content-center m-0">
              {currentPage > 1 && (
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={() => handleClick(currentPage - 1)}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
              )}

              {/* Display a subset of page links */}
              {/* totalPages - currentPage */}
              {Array.from({ length: Math.min((totalPages - currentPage +1), 10) }).map(
                (_, index) => {
                  const pageNumber = currentPage + index;
                  const isCurrentPage = pageNumber === currentPage;

                  return (
                    <li
                      key={index}
                      className={`min page-item ${isCurrentPage ? "active" : ""}`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handleClick(pageNumber)}
                      >
                        <span aria-hidden="true">{pageNumber}</span>
                      </a>
                    </li>
                  );
                }
              )}

              {/* Ellipsis if there are more pages */}
              {totalPages > 10 && (
                <li className="g10 page-item disabled">
                  <span className="page-link">...</span>
                </li>
              )}

              {currentPage < totalPages && (
                <li className="lessT page-item">
                  <a
                    className="page-link"
                    onClick={() => handleClick(currentPage + 1)}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default VideoPagination;
